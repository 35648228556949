<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="config.id == null && newConfigMarker">
            <h1>
              <div>Создание конфигурации конкурса
<!--                <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>-->
<!--                <input ref="fileInput" style="display: none" type="file" @change="loadJson">-->
              </div>
            </h1>
            <div class="chapterName">Наименование конкурса</div>
            <v-text-field label="Наименование" v-model="config.event.title" style="margin-bottom: 15px"></v-text-field>
            <div class="save-buttons mt-24">
              <v-btn color="primary" outlined @click="$router.push('/config/event')">Отмена</v-btn>
              <v-btn color="primary" @click="createConfig(); $forceUpdate()">Далее</v-btn>
            </div>
          </template>
          <template v-else>
            <template v-if="newConfigMarker">
              <h1>
                <div>Создание конфигурации конкурса
                  <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
                  <input ref="fileInput" style="display: none" type="file" @change="loadJson">
                </div>
              </h1>
            </template>
            <template v-else>
              <h1>
                <div>Редактирование<br> конфигурации id {{ config.id }}</div>
              </h1>
              <div class="creation-date">
                Дата создания<span>{{ config.createdDate | dateFormat }}</span>
              </div>
              <div class="creation-date">
                Дата изменения<span v-if="config.modifiedDate">{{ config.modifiedDate | dateFormat }}</span>
                              <span v-else>—</span>
              </div>
            </template>
            <div class="chapterName" style="margin-top: -20px">Наименование конкурса</div>
            <v-text-field label="Наименование" v-model="config.event.title"></v-text-field>
            <div class="chapterName" style="margin-top: 5px">Описание</div>
            <v-text-field label="Описание" v-model="config.event.description" style="margin-bottom: 35px"></v-text-field>

            <v-row>
              <v-col cols="12" lg="3">
                <div class="date-range-label" style="font-weight: 600; font-size: 16px;">Период проведения</div>
                <v-menu v-model="startDateMenu"
                        :close-on-content-click="false"
                        min-width="auto"
                        offset-y
                        transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        :value="config.beginDate | dateFormat"
                        clearable
                        label="Начало"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="config.beginDate"
                      :max="maxDate"
                      :min="minDate"
                      locale="ru-RU"
                      no-title
                      scrollable
                      @input="startDateMenu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu v-model="endDateMenu"
                        :close-on-content-click="false"
                        min-width="auto"
                        offset-y
                        transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        :value="config.endDate | dateFormat"
                        clearable
                        label="Окончание"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="config.endDate"
                      :max="maxDate"
                      :min="minDate"
                      locale="ru-RU"
                      no-title
                      scrollable
                      @input="endDateMenu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <div class="chapterName mb-2">Файлы</div>
            <div v-if="config.event.staticFiles">
              <v-row class="file-row align-center" v-for="(staticFile,index) in config.event.staticFiles" :key="index+'common'">
                <v-col cols="12" lg="4">
                    <a :href="staticFile.href" download class="file-link">
                      <v-icon>mdi-file-document-outline</v-icon>
                      {{ truncateStr(staticFile.href.substring(staticFile.href.lastIndexOf("/")+1), 35) }}
                    </a>
                </v-col>
                <v-col cols="12" lg="5" class="d-flex align-center">
                    <span class="mr-2">Для&nbsp;роли</span>
                    <v-select
                        outlined
                        :items="staticFileType"
                        item-text="title"
                        item-value="type"
                        v-model="staticFile.role"
                        @change="$forceUpdate()"
                    ></v-select>
                     <div class="delete ml-2"
                         @click="deleteFile(staticFile.href, config.event.staticFiles, index); $forceUpdate()">
                      <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                    </div>
                </v-col>
              </v-row>


              <input type="file" ref="staticFileInput" style="display: none" @change="uploadFile()">
              <a @click="$refs['staticFileInput'].click()" class="configAdd">+ Добавить файл</a>
            </div>

            <div class="chapterName" style="margin-bottom: -60px">Параметры конкурса</div>
            <div class="list config">
              <div v-for="(nomination, nominationIndex) in config.event.nominations" :key="nomination.id" class="list-item-wrap">
                <v-expansion-panels>
                  <v-expansion-panel :key="nomination.id">
                    <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                      <div class="list-item">
                        <div class="list-item-name">
                          <button class="toggleSwitch">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>
                            <h4 v-if="nominationIndex===0">Номинация</h4>
                            <q>{{ nomination.id / 100 }}.</q>
                            <v-text-field v-model="nomination.name" @click.stop="" label="Наименование номинации"></v-text-field>
                          </span>
                          <div v-if="config.event.nominations.length > 1"
                               class="delete"
                               @click.stop="deleteDirection(nomination.id)">
                            <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div  class="level2">
                        <div v-for="(field, fieldIndex) in nomination.fields" :key="nomination.id+'.'+field.id" class="list-item">
                          <div class="list-item-name">
                            <span>
                              <h4 v-if="fieldIndex===0">Позиция</h4>
                              <q>{{ nomination.id / 100 }}.{{ field.id / 100 }}.</q>
                              <v-text-field v-model="field.name" @click.stop="" label="Наименование позиции"></v-text-field>
                            </span>
                            <div v-if="nomination.fields.length>1" class="delete"
                                 @click="deleteField(nomination.id, field.id)"
                                 @click.stop="">
                              <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                            </div>
                          </div>
                          <div class="list-item-description">
                            <v-text-field v-model="field.description" label="Описание позиции"></v-text-field>
                          </div>
                          <div class="list-item-type">
                            <table>
                              <tr>
                                <td>
                                  <v-select
                                      label="Тип позиции"
                                      :items="fieldsType"
                                      item-text="title"
                                      item-value="type"
                                      v-model="field.type"
                                  ></v-select>
                                </td>
<!--                                <td>-->
<!--                                  {{ findTypeDescription(field.type) }}-->
<!--                                </td>-->
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <a class="configAdd" style="margin-left: 80px; margin-top: -24px!important;" href="#"
                         @click.prevent="addField(nomination)">+ Добавить позицию</a>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <a class="configAdd" href="#" @click.prevent="addNomination()">+ Добавить номинацию</a>
            </div>

            <div class="chapterName">Регионы</div>
            <div class="region-list-wrap">
              <div class="region-buttons">
                <v-btn color="primary" outlined @click="selectAllRegions">Выделить все</v-btn>
                <v-btn color="primary" outlined @click="config.event.regions = []">Снять выделения</v-btn>
              </div>
              <div class="region-list">
              <div v-for="(region, regionIndex) in regionList" :key="'regionIndex' + regionIndex">
                <v-checkbox
                    :label="region.name"
                    :value="region.code"
                    v-model="config.event.regions"
                ></v-checkbox>
              </div>
              </div>
            </div>

            <div class="save-buttons mt-24">
              <v-btn color="primary" outlined @click="$router.push('/config/event')">Отмена</v-btn>
              <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit()">Сохранить</v-btn>
            </div>
          </template>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>
import api from "@/modules/api";

export default {
  name: "EventConfigEdit",
  props: {},
  components: {},
  data() {
    return {
      config: {
        id: null,
        beginDate: null,
        endDate: null,
        event: {
          title: null,
          description: null,
          regions: [],
          staticFiles: [],
          nominations: [{id: 100, name: "", fields: [{id: 100, name: "", description: "", type: "FILE"}]}],
        },
      },
      startDateMenu: false,
      endDateMenu: false,
      minDate: '2022-01-01',
      maxDate: '2030-12-31',
      errorText: null,
      submitButtonDisabled: false,
      apiLoaded: false,
      newConfigMarker: true,
      staticFileType: [
        {type: 'all', title: 'Для всех'},
        {type: 'participant', title: 'Только для участников'},
        {type: 'controller', title: 'Только для проверяющих'},
      ],
      fieldsType: [
        {type: 'FILE', title: 'Файл', description: 'Прикрепление файла в позиции'},
        {type: 'LINK', title: 'Ссылка', description: 'Добавление ссылки в позиции'},
        {type: 'TEXT', title: 'Текст', description: 'Добавление текста в позиции'},
      ],
      typeDescription: 'Прикрепление файла в позиции',
      regionList: null,
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    //скачивает конфигурацию с бэка
    async loadConfig(id) {
      let req = await this.$getApi("/event/config/getConfig", {id: id})
      // console.log('req', req)
      if (req.ok) {
        // console.log('req.payload', req.payload)
        this.config = req.payload
        this.config.event = JSON.parse(this.config.eventJson)
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    //создание нового конфига по кнопке "Далее"
    async createConfig() {
      await this.submit(this.newConfigMarker);
      await this.loadConfig(this.id);
      // this.newConfig = false;
    },

    //загружает статический файл
    async uploadFile() {
      let file = this.$refs['staticFileInput'].files[0];
      if (file.size > 52428800) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        this.$refs['staticFileInput'].value = "";
        return
      }
      // console.log('file', file);

      let formData = new FormData();
      formData.append('file', file);

      let req = await api.postFile(
          "/event/config/saveStaticFile",
          {
            configId: this.id
          },
          formData,
          "POST"
      );
      // console.log('reqPost', req)
      if (req.ok) {
        if (this.config.event.staticFiles) {
          this.config.event.staticFiles.push({href: req.payload.href, role: "all"});
        } else {
          this.config.event.staticFiles = [];
          this.config.event.staticFiles.push({href: req.payload.href, role: "all"});
        }
        this.$forceUpdate();
        this.$refs['staticFileInput'].value = "";
        console.log('upload ok');
      } else {
        console.log('upload error', req.error);
      }
    },

    //удаляет файл
    async deleteFile(filePath, array, index) {
      let req = await api.delete("/event/config/deleteStaticFile", {
        configId: this.id,
        fileName: filePath
      });
      // console.log('req', req)
      if (req.ok) {
        console.log('delete ok');
        array.splice(index, 1);
      } else {
        console.log('delete error');
      }
    },

    //удаляет номинацию из конфига
    deleteDirection(nominationId) {
      for (let i = 0; i < this.config.event.nominations.length; i++) {
        if (this.config.event.nominations[i].id === nominationId) {
          this.config.event.nominations.splice(i, 1)
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    //удаляет позицию из конфига
    deleteField(nominationId, actionId) {
      for (let nomination of this.config.event.nominations) {
        if (nomination.id === nominationId) {
          for (let i = 0; i < nomination.fields.length; i++) {
            if (nomination.fields[i].id === actionId) {
              nomination.fields.splice(i, 1)
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    addNomination() {
      if (this.config.event.nominations == null) {
        this.config.event.nominations = []
      }
      this.config.event.nominations.push({
        id: 100,
        name: "",
        fields: [{id: 100, name: "", description: "", type: "FILE"}]
      })
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addField(nomination) {
      nomination.fields.push({id: 100, name: "", description: "", type: "FILE"})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    //после удаления какого-то элемента порядок номеров направлений-этапов-подэтапов собъется. Нужно все снова расставить
    //при добавлении аналогично
    ordinateNumbers() {
      for (let i = 0; i < this.config.event.nominations.length; i++) {
        let nomination = this.config.event.nominations[i]
        nomination.id = i * 100 + 100
        for (let j = 0; j < nomination.fields.length; j++) {
          let field = nomination.fields[j]
          field.id = j * 100 + 100
        }
      }
    },

    findTypeDescription(type) {
      for (let fType of this.fieldsType) {
        if (fType.type === type) {
          return fType.description
        }
      }
    },

    validateConfig(config) {
      if (config.event.title == null || config.event.title.length < 1) {
        return "Должно быть указано наименование конкурса"
      }
      if (config.event.description == null || config.event.description.length < 1) {
        return "Должно быть указано описание конкурса"
      }
      if (config.beginDate == null || config.beginDate.length < 1) {
        return "Должна быть указана дата начала проведения конкурса"
      }
      if (config.endDate == null || config.endDate.length < 1) {
        return "Должна быть указана дата окончания проведения конкурса"
      }

      let beginDate = "",
          endDate = "";
      if (config.beginDate.length < 11) {
        beginDate = new Date(config.beginDate + " 03:00:00")
      } else {
        beginDate = new Date(config.beginDate)
      }
      if (config.endDate.length < 11) {
        endDate = new Date(config.endDate + " 23:59:59")
      } else {
        endDate = new Date(config.endDate)
      }
      if ( beginDate > endDate) {
        return "Дата начала проведения конкурса должна быть меньше даты окончания проведения конкурса"
      }

      let validNom = this.validateNomination(config.event.nominations);
      if (!(validNom === true)) {
        return validNom
      }
      if (config.event.regions.length === 0) {
        return "Должен быть выбран хотя бы один регион"
      }
      return true
    },

    validateNomination(nominations) {
      if (nominations == null || nominations.length < 1) {
        return "Должна быть хотя бы одна номинация"
      }
      for (let i = 0; i < nominations.length; i++) {
        let nomination = nominations[i]
        if (nomination.name === "" || nomination.name.length === 0) {
          return "Должно быть указано наименование у номинации " + (i + 1)
        }
        if (nomination.id !== i * 100 + 100) {
          return "Идентификаторы должны быть кратны 100 и следовать по порядку"
        }
        for (let j = 0; j < nomination.fields.length; j++) {
          let field = nomination.fields[j]
          if (field.id !== j * 100 + 100) {
            return "Идентификаторы должны быть кратны 100 и следовать по порядку"
          }
          if (field.name == null || field.name === '' ||
              field.description == null || field.description === '' ||
              field.type == null || field.type === '') {
            return "Должны быть указаны все элементы позиции " + (j + 1) + " номинации " + (i + 1)
          }
        }
      }
      return true
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = function () {
        let nominations
        try {
          nominations = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        let validStat = reader.componentThis.validateNomination(nominations.nominations)
        if (validStat === true) {
          reader.componentThis.config.event.nominations = nominations.nominations
        } else {
          reader.componentThis.errorText = validStat
        }
      };
      this.$refs['fileInput'].value = ""
      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    async submit(newConfigMarker) {
      let config = {};
      if (newConfigMarker) {
        config.eventJson = JSON.stringify(this.config.event);
        // console.log('config', config);
        await this.saveConfig(config, newConfigMarker, "/event/config/saveConfig");
      } else {
        let validStat = this.validateConfig(this.config)
        if (validStat === true) {
          config = this.formConfig();
          config.eventJson = JSON.stringify(config.eventJson);
          // console.log('config', config);
          await this.saveConfig(config, newConfigMarker, "/event/config/updateConfig");
        } else {
          this.errorText = validStat
        }
      }
    },

    async saveConfig (config, newConfigMarker, path) {
      this.submitButtonDisabled = true
      let req = await this.$postApi(path, config)
      // console.log ('req', req)
      if (req.ok) {
        if (newConfigMarker) {
          this.id = req.payload.id
        } else {
          this.$router.push("/config/event")
        }
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.submitButtonDisabled = false
    },

    formConfig () {
      let config = {};
      config.id = this.config.id;
      config.createdDate = this.config.createdDate;
      config.modifiedDate = this.config.modifiedDate;
      config.staticFilesDirectory = this.config.staticFilesDirectory;
      if (this.config.beginDate.length < 11) {
        config.beginDate = new Date(this.config.beginDate + " 03:00:00").toISOString()
      } else {
        config.beginDate = this.config.beginDate
      }
      if (this.config.endDate.length < 11) {
        config.endDate = new Date(this.config.endDate + " 23:59:59").toISOString()
      } else {
        config.endDate = this.config.endDate
      }

      config.eventJson = this.config.event;
      // console.log('config.eventJson', config.eventJson)
      for (let i = 0; i < config.eventJson.nominations.length; i++) {
        config.eventJson.nominations[i].nominationName = "nomination" + (i + 1);
        for (let j = 0; j < config.eventJson.nominations[i].fields.length; j++) {
          config.eventJson.nominations[i].fields[j].fieldName = "nom" + (i + 1) + "_field" + (j + 1);
        }
      }
      // console.log('configFormed', config)
      return config
    },

    async getRegionList() {
      let req = await api.get("/getRegionsList");
      // console.log('reqRegionsList', req)
      if (req.ok) {
        this.regionList = req.payload;
        // Сортировка по алфавиту
        this.regionList.sort((a, b) => (a.name > b.name ? 1 : -1));
        //console.log('region', this.userRegion);
      } else {
        console.log('get regionList error');
      }
    },

    selectAllRegions() {
      for (let region of this.regionList) {
        this.config.event.regions.push(region.code);
      }
    },

    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },
  },

  async beforeMount() {
    this.id = this.$route.params.id;
    await this.getRegionList();
    if (this.id != null) {
      //режим редактирования
      await this.loadConfig(this.id);
      this.newConfigMarker = false;
    } else {
      this.apiLoaded = true;
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
.chapterName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  margin-top: 30px;
}

.file-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;

  .v-icon {
    margin-right: 10px;
    color: #0033a0;
  }
}

.list-item-description {
  .v-input {
    width: calc(100% - 100px);
    flex-grow: 0;
    margin-left: 30px;
  }
}

.list-item-type {
  tr {
    margin-top: -20px;
  }
  span {
    width: 100px;
    margin-left: 30px;
  }
  .v-input {
    width: calc(100% - 100px);
    //flex-grow: 0;
    margin-left: 30px;
  }
  .v-select {
    margin-top: -20px;
    margin-bottom: -25px;
    width: 250px;
    .v-label {
      color: $cgray!important;
    }
  }
  td:last-child {
    margin-left: -100px;
    color: #777779;
  }
}

.region-list-wrap {
  .region-buttons {
    display: flex;
    margin: 10px 0;
    .btn, button {
      margin-right: 8px;
      height: 30px;
    }
  }
  .region-list {
    max-height: 44vh;
    overflow-y: auto;
    .v-input--checkbox {
     margin: 0 0;
    }
  }

}

.edit, .delete {
  margin-left: -36px;
}

.level2 {
  padding-left: 80px !important;
}

</style>
